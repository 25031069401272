import React, { useState } from "react";
import { DataAccessEditor } from 'portal-common-ui';
import useDataAccess from "hooks/utils/dataAccess";
import useFeatureFlags from "hooks/utils/featureFlags";

const DataAccessTab = ({ dataRows, selectedGroups, onSelectGroup, updateSelectedGroups, allowFullPortfolioAccess, onDataAccessUpdate, setCanSave, setSavedGroup, sessionUser }) => {
  const { enabledSelectMethods } = useDataAccess();
  const { accountsSmallMerchantMidSelectionEnabled } = useFeatureFlags();
  const [pageTitle, setPageTitle] = useState('');
  const hasVTAssigned = !!sessionUser?.userApplicationAssignments?.results.find(a => a?.assignedApplication?.applicationGuid === 'VT');

  return (
    <>
      {/* <div id="tier-dropdown-portal" className="relative">
      </div> */}
      <div className="bg-theme-on-primary md:p-4 med:py-1 med:px-2 border border-theme-light-border border-t-0 border-b-0 overflow-y-auto overflow-x-hidden w-full h-[380px] t-xl:h-full">
          <DataAccessEditor
            allowFullPortfolioAccess={allowFullPortfolioAccess}
            dataRows={dataRows}
            onDataAccessUpdate={onDataAccessUpdate}
            setCanSave={setCanSave}
            onSetPageTitle={setPageTitle}
            setSavedGroup={setSavedGroup} 
            selectedGroups={selectedGroups}
            redirectSingleLineageToMidSelector={false}
            enabledSelectMethods={enabledSelectMethods}
            allowSmallMerchantMidSelection={accountsSmallMerchantMidSelectionEnabled && sessionUser?.roleObject?.roleType === "EXTERNAL" && !hasVTAssigned}
          />
      </div>
    </>
  );
}

export default DataAccessTab;
