import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // TODO: Add dependency to lib
import useFeatureFlags from 'hooks/utils/featureFlags';
import { SidebarToggleIcon, SidebarHeaderIcon } from 'assets/icons';
import usePermissions from 'hooks/session/permissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSession from 'hooks/session/session';
import { LocalizedText, useLocale, AccessibleIcon } from 'portal-common-ui';
import { faHouse } from '@fortawesome/sharp-light-svg-icons';
import { faCircleUser, faRectangleList, faShop, faCartShopping } from '@fortawesome/pro-light-svg-icons';
import { localeKeys } from 'typings/translate';
import { usePortalAppContext } from 'portal-common-ui';

interface SidebarProps {
    displayUserMngtLink: boolean;
}

interface primaryNavigationItems {
    nameKey: localeKeys;
    [key: string]: any;
}

const NavigationSidebar: React.FC<SidebarProps> = ({ displayUserMngtLink }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleSidebar = () => setExpanded(!expanded);
    const expandSidebar = () => { if (!expanded) setExpanded(true); }
    const collapseSidebar = () => { if (expanded) setExpanded(false); }
    const {hasPermission} = usePermissions();
    const session = useSession();
    const { translateToString } = useLocale();

    const sessionUser = session.user;
    
    const { accountsDataAccessManagementEnabled, accountsMerchantDemographicsEnabled, accountsApplicationManagementEnabled, accountsGuestUserEnabled } = useFeatureFlags();
    let primaryNavigation: primaryNavigationItems[] =  [
      {
          exact: true,
          name: "Home",
          icon: faHouse,
          url: "/",
          nameKey: "homepage.sidebar.home"
      },
      {
          exact: false,
          name: "User Management",
          icon: faCircleUser,
          url: "/user-management",
          nameKey: "homepage.sidebar.userManagement"
      },
      // uncomment to test viewing user when search is broken
      // {
      //   exact: false,
      //   name: "View User",
      //   icon: "fa-user-circle",
      //   url: "/user/view/780d6c06-8422-4728-914c-0be4a1e98dfb"
      //   // url: "/user/view/ae09d8d3-d58d-422c-a771-a5cede06aad4"
      //   // url: "/user/view/ae09d8d3-d58d-422c-a771-a5cede06aad5"
      //   // url: "/user/view/96a8d830-5036-4571-8d6f-1735a1db7576"
      // },
    ];


    if (accountsDataAccessManagementEnabled) {
      primaryNavigation.push({
        exact: false,
        name: "Location Access Management",
        icon: faRectangleList,
        url: "/data-access-management",
        nameKey: "homepage.sidebar.dataAccessManagement"
      });
    }
    if(accountsMerchantDemographicsEnabled && !sessionUser.isGuestUser)
     {
        primaryNavigation.push({
            exact: false,
            name: "Merchant Demographics",
            icon: faShop,
            url: "/merchant-demographics",
            nameKey: "homepage.sidebar.merchantDemographics"
        });
    }

    if(accountsApplicationManagementEnabled && hasPermission('SUBSCRIPTION_MANAGER'))
    {
        primaryNavigation.push({
            exact: false,
            name: "Product Offerings",
            icon: faCartShopping,
            url: "/product-offerings",
            nameKey: "homepage.sidebar.productOfferings"
        });
    }
      
    if (!hasPermission('VIEW_USERS')) { 
        primaryNavigation = primaryNavigation.filter(c => c.name !== 'User Management');
    }
    if (!hasPermission('MANAGE_DATA_ACCESS_GROUPS')) { 
        primaryNavigation = primaryNavigation.filter(c => c.name !== 'Location Access Management');
    }

    const {mobSidebar ,handleMobSidebar} = usePortalAppContext();

    return(
        <>
        <div className="relative navigation--sidebar flex bg-theme-secondary m-0 p-0 z-10 before:bg-theme-dark before:h-full before:absolute before:inline-block before:w-0.75 before:left-0 before:top-0 lrg:hidden">
            <nav onMouseEnter={expandSidebar} onMouseLeave={collapseSidebar} onClick={collapseSidebar} aria-label={`Accounts navigation`} aria-expanded={expanded} role="navigation"
                 className={ `flex flex-col justify-between transition-width duration-500 ease-in-out ${expanded ? "w-sidebar-expanded after:bg-theme-dark after:h-full after:absolute after:inline-block after:w-px after:left-[68px] after:top-0" : "w-[68px]"} `} data-test="navigation-sidebar" >
                <div className="upper-sidebar-container">
                    <header className="flex items-center text-theme-on-primary h-header">
                        <div className="w-[68px] min-w-[68px] flex justify-center"><span className="ml-0.5"><SidebarHeaderIcon aria-labelledby='application-title-label'/></span></div>
                        {expanded && <h3 className={`pl-4 whitespace-nowrap transition-all duration-1000 ease-in capitalize ${expanded ? 'opacity-100' : 'opacity-0'}`}>
                            {expanded && <LocalizedText localeKey="homepage.applicationTitle" id="application-title-label" />}
                        </h3> }
                    </header>
                    <ul>
                        { primaryNavigation.map( (item ) => (
                            <li onMouseEnter={expandSidebar} className="text-theme-on-primary flex relative" key={item.nameKey}>
                                <NavLink 
                                    aria-label={item.name} 
                                    exact={item.exact} className={`py-2 hover:w-full hover:beforeLinkLine`}
                                    data-test={`navigation-${item.name.replace(/\s/g, '-').toLowerCase()}`}
                                    activeClassName="bg-theme-info w-full beforeLinkLine" to={item.url}>
                                    <div className="flex flex-nowrap items-center h-8">
                                        <div className="w-[68px] min-w-[68px] flex justify-center">
                                            <AccessibleIcon icon={item.icon} labeledBy={`${item.nameKey}.label`} classes="text-[20px]"/>
                                        </div>
                                        <span id={`${item.nameKey}.label`} className={`pl-4 text-sm font-normal transition-all duration-1000 ease-in ${expanded ? 'opacity-100' : 'opacity-0'}`}>
                                            {expanded && translateToString(item.nameKey)} 
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
                <button aria-expanded={expanded}
                    aria-label="Toggle sidebar navigation visibility"
                    className={`fixed bottom-5 ${expanded ? 'left-72' : 'left-6 rotate-180'}`}
                    onClick={toggleSidebar}
                    >
                    <SidebarToggleIcon/>
                </button>
            </nav>
        </div>
    
    {/* NOTE: Below code snippet is written for mob sidebar coz its design & functionality differs from desktop version, else above comp would be refactored */}
    { mobSidebar &&
        <nav className={mobSidebar ? 'hidden lrg:block fixed top-0 left-0 z-[1050] w-full h-screen ease-in-out duration-500' : 'fixed left-[-100%]'} onClick={handleMobSidebar}>
            <div className={'flex flex-col bg-theme-secondary text-theme-on-primary w-1/2 sml:w-[80%] h-full'}>
                <header className='flex p-6'>
                    <NavLink to={"/"}>{<SidebarHeaderIcon/>}</NavLink>
                    <h1 className='font-bold text-[22px] ml-8 my-0'><LocalizedText localeKey="homepage.applicationTitle" id="application-title-label" /></h1>
                </header>

                <div>
                    {primaryNavigation.map((item, key) => (
                    <NavLink to={item.url} key={key}>
                        <div className='flex items-center p-6 mx-3 rounded-lg cursor-pointer hover:bg-theme-primary active:bg-theme-primary' onClick={handleMobSidebar}>
                        <div><FontAwesomeIcon icon={item.icon} className='text-theme-on-primary text-[25px]' /></div>
                        <h3 className='text-4 ml-6 font-normal capitalize'>{translateToString(item.nameKey)}</h3>
                        </div>
                    </NavLink>
                    )
                    )}
                </div>
            </div>
        </nav>
    }
    
    </>
    )
};

export default NavigationSidebar;