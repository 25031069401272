import { useEffect, useState } from 'react';
import { useGlobal } from 'libs/reactn';
import config from 'config/config';
import useAlertQueue from 'hooks/alertQueue';
import { useUser, useApi } from 'portal-common-ui';
import { useApplicationsStore } from 'store/applicationStore';

const useApplications = () => {
  
  const [apps, setApps] = useGlobal('apps');
  const [auth] = useGlobal('auth');
  const { getAuthenticatedSessionUser, authenticatedUser } = useUser();
  const { appList, internalAppList, setAppList, setInternalAppList, fetchAppList, loading, loadingAvailableApps } = useApplicationsStore();
  const alertQueue = useAlertQueue();
  let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));

  const env = ['mdev', 'mqa'].includes(config.env.name) ? config.env.name.replace('m', '') : config.env.name;
  const domainPrefix = ['mdev', 'mqa'].includes(config.env.name) ? 'np-' : '';
  const brand = brandDetails.brandGuid.toLowerCase();
  const assignedBrand = auth?.session?.user?.brand.toLowerCase();

  const appListUrl = config.API.userUrl2;
  const relativeUrl = `/email/${auth?.session?.user?.email}?includeInactive=false&hydrateApplications=true&applicationPage=1&applicationPageSize=25&hydrateHierarchies=false&hydrateHierarchyTags=false&hierarchyPage=1&hierarchyPageSize=25`;
  const appListApi = useApi(`${appListUrl}${relativeUrl}`);
  const apiOptions = {
    cachePolicy: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const appSearchApi = useApi(`${appListUrl}/search/applications`, apiOptions);
  const showSysnet = config.features.hasOwnProperty('showSysnet') ? config.features.showSysnet : true;
  const showReporting = config.features.showReporting;

  useEffect(() => {
    if(appListApi.error) {
      alertQueue.enqueueAlert({ alertType: 'modal', title: 'Error', messageType: 'error', message: 'An error occurred while processing your request.  Please try again later.' });
    }
  }, appListApi.error);

  const getAvailableAppsForSubscription = async (targetRoleId, selectedDataAccess) => {
    appSearchApi.abort();
    const response = await appSearchApi.post({
      accountsRoleId: targetRoleId,
      createUserHierarchyAssignmentPayloads: selectedDataAccess
    });
    return response.status === 400 ? response : response.content;
  };

  const filterAppList = (myProfile, includeSysnet = false) => {
    const hasBankAssigned = (bankLabel) => !!myProfile.dataAccess?.find(h => h?.nodes[0].selectedChild.label === bankLabel);
    const hasExternalRole = myProfile?.roleObject.roleType === "EXTERNAL";
    const newAppList = myProfile.userApplicationAssignments.results.filter(app => {
      const isSysnet = app.assignedApplication.applicationGuid === 'SYSNET';
      const isReporting = app.assignedApplication.applicationGuid === 'MERCHANT_PORTAL_REPORTING';
      const isSynovusApp = ['ACE_CUSTOMER_ENGAGEMENT', 'DIMS', 'MERCHANT_PORTAL_ACCOUNTS'].includes(app.assignedApplication.applicationGuid);
      let includeApp = [
        "ACE_CUSTOMER_ENGAGEMENT",
        "CES_COMPETITIVE_BENCHMARK",
        "CES_REPUTATION_MANAGEMENT",
        "MERCHANT_PORTAL_REPORTING",
        "SYSNET",
        "DIMS",
        "MERCHANT_PORTAL_ACCOUNTS",
        "VT",
        "CES_GIFT",
      ].includes(app.assignedApplication.applicationGuid);
      if (brand === 'synovus') {
        includeApp = isSynovusApp;
      } else if (isSysnet) {
        includeApp = showSysnet && ((hasBankAssigned("9305") && hasExternalRole) || includeSysnet);
      } else if (isReporting) {
        includeApp = showReporting;
      }
      return includeApp;
    });
    return newAppList;
  }

  const mapApplicationList = (myProfile) => {
    const newAppList = filterAppList(myProfile);
    const mappedAppList =  newAppList.map(app => {
        const appCreatedTime = new Date(app.createdDateTime).getTime();
        const newlyAdded = authenticatedUser?.lastLoginDate
          ? authenticatedUser?.lastLoginDate < appCreatedTime && appCreatedTime > 1696887594982
          : true;
        return { ...app, newlyAdded };
      });
      return mappedAppList;
  };

  const getApplicationsList = async ( forceReload = false) => {
    if (sessionStorage.getItem('restrictGetApplicationsList') === 'true') {
      sessionStorage.setItem('restrictGetApplicationsList', 'false');
      return;
    }
    try {
        if (appList.length > 0 && !forceReload) {
          return appList;
        }
        const myProfile = await fetchAppList(getAuthenticatedSessionUser);

        if(myProfile) {
          const mappedAppLists = mapApplicationList(myProfile);
          setAppList(mappedAppLists);
          setInternalAppList(filterAppList(myProfile, true));
        }
    } catch(err) {
      console.log(err);
    }
  };

  const getAppIds = (list) => {
    // userApplicationAssignments.results[].assignedApplication.applicationId
    return list?.map(a => a.applicationId) || [];
  };

  
  const pollForSubscribedAppsChange = async(isRemovingSub, retryDelay, maxRetries) => {
    // poll the profile API for changes until we see updated results or reach the max retries
    setAppList([]);
    const shouldRetry = (lastProfile, currentProfile) => {
      if (isRemovingSub) return true;
      if (!lastProfile) return true;

      const prevApps = lastProfile?.userApplicationAssignments?.results?.map(x => x.applicationId);
      const currentApps = currentProfile?.userApplicationAssignments?.results?.map(x => x.applicationId);

      return lastProfile && 
             currentProfile && 
             (prevApps?.every(p => currentApps?.includes(p)) && currentApps.every(c => prevApps.includes(c)));
    }

    const myProfile = await fetchAppList(getAuthenticatedSessionUser, shouldRetry, 3000, 3);
    const newApps = mapApplicationList(myProfile);
    setAppList(newApps);
  }

  const analyticsSSOUrl = config.API.analyticsSSO.replace('{0}', brand);

  const giftBrandDomainConfig = {
    hpy: { giftURL: config.API.giftHPYUrl, giftBrandDomain: 'heartland.us' },
    ova: { giftURL: config.API.giftOVAUrl, giftBrandDomain: 'ovation.us' },
    default: { giftURL: config.API.giftGPIUrl, giftBrandDomain: 'acegp-internal.com' }
  };

  const { giftURL, giftBrandDomain } = giftBrandDomainConfig[brand] || giftBrandDomainConfig.default;

  // todo: have branded urls added to the brand details instead of added as a jenkins constant.
  const appLinksMap = {
    'ACE_CUSTOMER_ENGAGEMENT': analyticsSSOUrl,
    'CES_COMPETITIVE_BENCHMARK': analyticsSSOUrl,
    'CES_REPUTATION_MANAGEMENT': analyticsSSOUrl,
    'MERCHANT_PORTAL_REPORTING': brand === 'citi' ? `${config.API.citiReportingUrl}/${brand || ''}` : `${config.API.reportingUrl}/${assignedBrand || ''}`,
    'SYSNET': config.API.sysnetUrl || `https://${env === 'dev' ? 'dev.sysnet.ie' : env === 'qa' ? 'testsite-gpnna.sysnetqaa.com' : ''}/services/clientapi/oauth/callback/oidc`,
    'DIMS': brand === 'citi' ? config.API.citiDimsUrl : config.API.dimsUrl || `https://${config.env.isDevOrQa ? `${env}3.np-` : (env === 'cert') ? 'sit.' : ''}disputesmanagement.heartland.us`,
    'VT': config.API.vtUrl || `https://${env === 'prod' ? '' : `${env}.`}${domainPrefix}vt.globalpay.com`,
    'CES_GIFT': giftURL || `https://${env === 'prod' ? '' : (env === 'cert') ? 'stg-ui.' : ''}gift.${giftBrandDomain}/gift/b2c-login`,
  }
  
  return {
    getApplicationsList,
    getAvailableAppsForSubscription,
    pollForSubscribedAppsChange,
    filterAppList,
    appLinksMap,
    appList,
    internalAppList,
    loading,
    loadingAvailableApps
  }
};

export default useApplications;
