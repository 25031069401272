import React, { useEffect } from 'react'
import Dashboard from 'pages/Dashboard/Dashboard';
import { Switch, useLocation, useHistory} from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { PortalAppContextProvider } from 'portal-common-ui';
import { useGlobal, getGlobal } from 'libs/reactn';
import useAlertQueue from 'hooks/alertQueue';
import useFeatureFlags from 'hooks/utils/featureFlags';
import useFilterState from 'hooks/use-filter-state';
import useSortState from 'hooks/use-sort-state';
import Login from 'core/session/Login';
import Logout from 'core/session/Logout';
import LoggedOut from 'pages/LoggedOut';
import PermissionedRoute from 'core/route/PermissionedRoute';
import PublicRoute from 'core/route/PublicRoute';
import config from 'config/config';
import ViewDataAccessGroupDetails from './pages/ViewDataAccessGroupDetails/ViewDataAccessGroupDetails';
import {
  Home,
  UserManagement,
  Documentation,
  SelfRegister,
  ErrorPage,
  CreateUser,
  CreateDataAccessGroup,
  ViewUser,
  MyProfile,
  DataAccessManagement,
  MerchantDemographics,
  MerchantDemographicsDetails,
  CloneUser,
  HierarchySelectorTest,
  ProductOfferings,
  CurrentSubscriptions,
  LandingPage
} from 'pages';
import GAListener from './core/GAListener';
import 'portal-common-ui/dist/portal-common-ui.css';
import appLocaleKeys from 'config/constants/masterTranslations';

function App() {
  const location = useLocation();
  const alertQueue = useAlertQueue();
  const history = useHistory();
  const [auth, setAuth] = useGlobal('auth');
  const [localeDictionary] = useGlobal('localeDictionary');
  const { accountsMultiPortfolioEnabled, accountsBrandListEnabled } = useFeatureFlags();  // todo: remove when these feature flags are closed
  const featureFlags  = useFeatureFlags(); 
  const appFilterState = useFilterState();
  const appSortState = useSortState();

  const getMyProfile = (fetch = true) => {
    if (!auth?.myProfile?.portfolios) {
      return fetch ? portalUser.getAuthenticatedSessionUser() : null;
    } else {
      return auth.myProfile;
    }
  };

  const setMyProfile = (user) => {
    setAuth(newAuth => {
      newAuth.myProfile = user;
      newAuth.session.user.firstName = user?.firstName;
      newAuth.session.user.lastName = user?.lastName;
    });
  };

  const getSessionToken = () => {
    return getGlobal()?.auth?.session?.sessionToken;
  };

  return (
    <GAListener>
      <PortalAppContextProvider
        config={config}
        sessionUser={auth?.session?.user}
        getMyProfile={getMyProfile}
        setMyProfile={setMyProfile}
        getSessionToken={getSessionToken}
        enqueueAlert={alertQueue.enqueueAlert}
        routerHistory={history}
        multiPortfolioEnabled={accountsMultiPortfolioEnabled}  // todo: remove when this feature flag is closed
        brandListEnabled={accountsBrandListEnabled} // todo: remove when this feature flag is closed
        featureFlags={featureFlags}
        appFilterState={appFilterState}
        appSortState={appSortState}
        appLocaleKeys={appLocaleKeys}
        localeDictionary={localeDictionary}
        appPreferences={{ 
          locale: auth.myProfile?.locale,
          timeFormat: auth.myProfile?.timeFormat,
          dateFormat: auth.myProfile?.dateFormat,
        }}
      >
      <Switch>
        <PublicRoute path="/UserNotFound">
          <ErrorPage errorPageType='UnknownEmployee' />
        </PublicRoute>
        <PublicRoute path="/inactive">
          <ErrorPage errorPageType='InactiveUser' />
        </PublicRoute>
        <PublicRoute path="/forbidden">
          <ErrorPage errorPageType='DeactivatedUser' />
        </PublicRoute>
        <PublicRoute path="/LoginError">
          <ErrorPage errorPageType='Error' />
        </PublicRoute>
        <PublicRoute path="/SelfRegisterError">
          <ErrorPage errorPageType='SelfRegisterError' />
        </PublicRoute>
        <PublicRoute path="/login">
          <Login />
        </PublicRoute>
        <PublicRoute path='/selfRegister'>
          <SelfRegister />
        </PublicRoute>
        <PublicRoute path="/logout">
          <Logout />
        </PublicRoute>
        <PublicRoute path="/loggedOut">
          <LoggedOut />
        </PublicRoute>
        <PermissionedRoute exact path="/">
          <LandingPage key={location.key}/>
        </PermissionedRoute>
        <PermissionedRoute exact path="/user/new">
          <CreateUser key={location.key}/>
        </PermissionedRoute>
        <PermissionedRoute exact path="/merchant-demographics">
          <MerchantDemographics /> 
        </PermissionedRoute>
        <PermissionedRoute exact path="/merchant-demographics/view/:hierarchyId">
          <MerchantDemographicsDetails key={location.key} /> 
        </PermissionedRoute>
        <PermissionedRoute exact path="/data-access-management">
          <DataAccessManagement /> 
        </PermissionedRoute>
        <PermissionedRoute exact path="/data-access-group/view/:groupId">
          <ViewDataAccessGroupDetails key={location.key}/> 
        </PermissionedRoute>
        <PermissionedRoute exact path="/data-access-group/new">
          <CreateDataAccessGroup key={location.key}/>
        </PermissionedRoute>
        <PermissionedRoute exact path="/index.html">
          <Home />
        </PermissionedRoute>
        <PermissionedRoute exact path="/user-management">
          <UserManagement />
        </PermissionedRoute>
        <PermissionedRoute exact path="/docs">
          <Documentation />
        </PermissionedRoute>
        <PermissionedRoute exact path="/profile">
          <MyProfile  key={location.key} />
        </PermissionedRoute>
        <PermissionedRoute exact path="/user/view/:userId">
          <ViewUser key={location.key} />
        </PermissionedRoute>
        <PermissionedRoute exact path={["/clone/user/:userId?", "/clone/new"]}>
          <CloneUser key={location.key}/>
        </PermissionedRoute>
        <PermissionedRoute exact path="/product-offerings">
          <ProductOfferings />
        </PermissionedRoute>
        <PermissionedRoute exact path="/product-offerings/current-subscriptions">
          <CurrentSubscriptions key={location.key}/>
        </PermissionedRoute>
        {config.mockApi && (
            <PermissionedRoute exact path="/hierarchySelectorTest">
              <HierarchySelectorTest />
            </PermissionedRoute>
        )}
      </Switch>
      </PortalAppContextProvider>
    </GAListener>
  );
}

// const exportedApp = (config.launchDarkly.clientId) ? 
// withLDProvider({ clientSideID: config.launchDarkly.clientId })(App)
// : App;

// export default exportedApp;

export default App;
