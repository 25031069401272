import React, { useState } from 'react';
import {Link} from "react-router-dom";
import {useGlobal} from "libs/reactn";
import UserAvatar from "assets/libs/react-user-avatar";
import SubNavMenu from "./SubNavMenu";
import { NavbarAppsIcon } from 'assets/icons';
import config from 'config/config';
import navHeaderItems from './navigationHeaderItems';
import Overlay from 'components/Overlay/Overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { AccessibleIcon, useLocale } from 'portal-common-ui';
import { localeKeys } from 'typings/translate';
import {faBars } from '@fortawesome/free-solid-svg-icons'
import { usePortalAppContext } from 'portal-common-ui';
import { useApplicationsStore } from 'store/applicationStore';
import { ReactComponent as Heartland } from "assets/img/Heartland.svg";


const getIcon = (label: string, userDisplayName: string, isActive: boolean) => {
  if ( label === "profile" ) {
    return(
      <UserAvatar size="40" name={userDisplayName} />
    );
  }
  if ( label === "support" ) {
    return(
      <FontAwesomeIcon icon={isActive ? faQuestionCircle : faCircleQuestion} className={`mt-1 text-2xl ${isActive && "text-theme-secondary"} hover:text-theme-dark`}/>
    );
  }
  return <NavbarAppsIcon className={`stroke-current text-theme-medium ${isActive && "text-theme-dark"} hover:text-theme-dark`}/>;
};

interface NavigationHeaderProps {
  brandName: string;
  brandLogo: string;
  isWhiteLabel: boolean;
  userInitials: string;
  userDisplayName: string;
  userEmailAddress: string;
  contacts: { name: string; phone: string; email: string }[];
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({ 
    brandName, 
    brandLogo, 
    isWhiteLabel, 
    userInitials, 
    userDisplayName, 
    userEmailAddress, 
    contacts, 
  }) => {

  type overlayType = { isActive: boolean; dark: boolean }
  let overlay: overlayType, setOverlay: any;  // to do: need to update with precise typing instead of any type
  [overlay, setOverlay] = useGlobal('overlay');
  const [navItems, setNavItems] = useState(navHeaderItems);
  const { translateToString } = useLocale();
  const profileLogoutLabel = `${translateToString('homepage.myProfile.name')} + ${translateToString('homepage.logout.name')}`; 
  
  let brandDetails = JSON.parse(sessionStorage.getItem('brandDetails'));
  let titleKey = `common.${brandDetails.htmlTitle}`;
  let title = `${translateToString(titleKey)}`;
  document.title = title ? title : 'Account Dashboard';
  
  const closeSubNav = () => {
    setNavItems(prevState => {
        const items = prevState.map(item => {
          item.isActive = false;
          return item;
        });
        return items;
    });
  };

  const toggleOverlay = (label: string): void => {
      if (overlay.isActive) {
          closeSubNav();
          setOverlay((newOverlay: any): void => {
            newOverlay.isActive = false;
            newOverlay.dark = false;
          });
      } else {
          setOverlay((newOverlay: any): void => {
            newOverlay.isActive = true;
            if (label === 'support') newOverlay.dark = true;
          });
      }
  };

  const toggleSubNav = (label: string): void => {
    setNavItems( prevState => {
        const index = prevState.findIndex(item => item.label === label);
        const toggledItem = { ...prevState[index], isActive: true };
        prevState.splice(index, 1, toggledItem); 
        return [...prevState];
    });
    toggleOverlay(label);
  };

  const {handleMobSidebar} = usePortalAppContext();
  const { isHeartlandGiftUser } = useApplicationsStore();

  return(
    <>
    <header className="navigation--header sticky flex justify-between items-center h-header bg-theme-on-primary border-b border-theme-light-background py-3 px-4 sml:px-4 z-10">
      { overlay.isActive && <Overlay dark={overlay.dark} /> }
      
      <div className='flex items-center'>
        <div className='hidden lrg:block' onClick={handleMobSidebar}><FontAwesomeIcon icon={faBars} size="lg" className='cursor-pointer'/></div>
        
        <div className='pl-2'>
          {!isWhiteLabel &&
            <Link className="brand-logo" to={"/"}>
                {isHeartlandGiftUser ? (
                  <Heartland />
                ) : (
                  <img src={brandLogo} className="w-logo h-[35px] w-auto" alt={`${brandName} Logo`} />
                )}            
            </Link>
          }
        </div>
      </div>
      
      <nav className="z-[11]" aria-label="Header Navigation" role="navigation" data-test="header-navigation">
        <ul className="navigation--header__nav flex justify-center items-center w-48 sml:w-auto">
          {
            navItems.map( (item, key) => {
              const { isActive, label, labelKey, subNavItems } = item;
              return(
                <li
                  key={key}
                  aria-expanded={isActive}
                  className={`${isActive ? "expanded" : "collapsed"} 
                    ${label === "profile" ? "with-indicator w-14" : "w-10"} 
                    relative h-header flex justify-center items-center`}
                >
                  <button
                    aria-label={label === 'profile' ? profileLogoutLabel : translateToString(labelKey)}
                    className={`btn btn-navigation z-20 p-1 text-theme-medium bg-transparent ${label === 'profile' ? isActive ? 'triangle after:transform after:rotate-180' : 'triangle' : ''}`}
                    onClick={() => { if(!isActive) toggleSubNav(label) }}
                    data-test={`navigation-${label}`}
                    >
                    { getIcon(label, userInitials, isActive) }
                  </button>


                  { isActive &&
                    <SubNavMenu
                      subNavItems={subNavItems}
                      type={label}
                      userInitials={userInitials}
                      userDisplayName={userDisplayName}
                      userEmailAddress={userEmailAddress}
                      toggleOverlay={toggleOverlay}
                      toggleSubNav={toggleSubNav}
                      contacts={contacts}
                    />
                  }
                </li>
              );
            })
          }
        </ul>
      </nav>
    </header>
</>
  )
};

export default NavigationHeader;