import React from 'react';
import { Button, ButtonType, LocalizedText, AccessibleIcon } from 'portal-common-ui';
import { faExclamationTriangle, faCheck, faTimesCircle, faInfo } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import Overlay from 'components/Overlay/Overlay';

const Alert = ({
  type,
  onContinue,
  onCancel,
  isProcessing = false,
  title,
  message,
  message2,
  continueCaption,
  cancelCaption,
  processingCaption,
  titleKey,
  messageKey,
  message2Key,
  continueCaptionKey,
  cancelCaptionKey,
  processingCaptionKey
}) => {
  let icon;
  let defaultHeader;
  let className = type;
  let utilityClasses;
  switch (type) {
    case 'success':
      // icon = 'check';
      icon = faCheck;
      defaultHeader = 'success';
      utilityClasses = { background: 'bg-theme-success sml:bg-theme-on-primary', textColor: 'text-theme-success', btnBorder: 'border border-solid border-theme-success', btnBackground: 'bg-theme-success', logoBg: 'sml:text-theme-success', };
      break;
    case 'error':
      className='danger';
      // icon = 'exclamation';
      icon = faTimesCircle;
      defaultHeader = 'Error!';
      utilityClasses = { background: 'bg-theme-danger sml:bg-theme-on-primary', textColor: 'text-theme-danger', btnBorder: 'border border-solid border-theme-danger', btnBackground: 'bg-theme-danger', logoBg: 'sml:text-theme-danger' };
      break;
    case 'warning':
      className='warning';
      // icon = 'exclamation-triangle';
      icon = faExclamationTriangle;
      defaultHeader = 'warning';
      utilityClasses = { background: 'bg-theme-warning sml:bg-theme-on-primary', textColor: 'text-theme-warning', btnBorder: 'border border-solid border-theme-warning', btnBackground: 'bg-theme-warning', logoBg: 'sml:text-theme-warning' };
      break;

    case 'mobileInfo':
      // className='info'
      defaultHeader = 'info';
      utilityClasses = { background: 'bg-theme-info sml:bg-theme-on-primary', textColor: 'text-theme-info', btnBorder: 'border border-solid border-theme-info', btnBackground: 'bg-theme-info', logoBg: 'sml:text-theme-info'  };
      break;

    case 'info':
    default:
      // icon = 'this.png';
      icon = faInfo;
      defaultHeader = 'info';
      utilityClasses = { background: 'bg-theme-info sml:bg-theme-on-primary', textColor: 'text-theme-info', btnBorder: 'border border-solid border-theme-info', btnBackground: 'bg-theme-info', logoBg: 'sml:text-theme-info'  };
  }

  const onCloseModalClick = () => {
    onCancel();
  };
  
  const onContinueClick = () => {
    onContinue();
  };

  const onCancelClick = () => {
    onCancel();
  }

  // to do: replace buttons with button component from common ui
  return (
    <>
      <Overlay dark className={'z-[1053]'} />
      <div 
        className={`alert flex sml:flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-alert med:w-[95%] sml:text-center shadow-alert bg-theme-on-primary z-[1054]`}
        data-test="alert-container"
      >
          <div className={`w-14 flex items-center justify-center ${utilityClasses.background} sml:w-full sml:py-4 sml:bg-theme-on-primary`} data-test="alert-icon">
              <AccessibleIcon icon={icon} labeledBy="alert-title-label" classes={`text-2xl ${utilityClasses.background} text-theme-on-primary ${utilityClasses.logoBg}`}/>
              <p className={`text-xl font-medium leading-4 ml-2 ${utilityClasses.textColor} hidden sml:block`}>{titleKey ? <LocalizedText localeKey={titleKey}/> : title}</p>
          </div>

        <hr />

        <div className="content w-full h-full p-4 bg-theme-on-primary text-theme-medium">

          <button 
            className="close bg-theme-medium text-theme-on-primary float-right w-5 h-5 rounded-full text-xs flex justify-center items-center med:hidden"
            disabled={isProcessing}
            onClick={onCloseModalClick}
          >
            <AccessibleIcon icon={faXmark} localeKey="shared.alerts.closeButtonAltText" />
          </button>

          <h1 id="alert-title-label" className={`text-xl font-medium leading-4 mb-4 ${utilityClasses.textColor} sml:hidden`} data-test="alert-title">{titleKey ? <LocalizedText localeKey={titleKey}/> : title}</h1>
          <p className="text-base w-alert-message med:w-auto font-normal leading-5 text-theme-medium mb-4" data-test="alert-message">{messageKey ? <LocalizedText localeKey={messageKey}/> : message}</p>
          {(message2 || message2Key) && <p className="text-base w-alert-message med:w-auto font-normal leading-5 text-theme-medium mb-4" data-test="alert-message2">{message2Key ? <LocalizedText localeKey={message2Key}/> : message2}</p>}
          <div className="buttons">
            {isProcessing
              ? <div className="inline-block my-3">
                  <Button
                    buttonType={ButtonType.PrimaryButton}
                    buttonClass="sml:hidden"
                    defaultContainerClass={"inline-block"}
                    disabled={isProcessing}
                    isProcessing={isProcessing}
                    onClick={onContinueClick}
                    title={continueCaption}
                    processingCaption={processingCaption}
                    titleKey={continueCaptionKey}
                    processingCaptionKey={processingCaptionKey}
                  />
                </div>
              : <>
                <Button 
                    buttonType={ButtonType.PrimaryButton}
                    buttonClass="sml:hidden"
                    defaultContainerClass={"inline-block"}
                    onClick={onContinueClick}
                    testDataAttribute="alert-continue-caption"
                    title={continueCaption}
                    titleKey={continueCaptionKey}
                  /> 

                  {/* Below button will only show for mobile devices & above will hide */}
                  <Button 
                    buttonType={ButtonType.MobilePrimary}
                    defaultContainerClass={"inline-block"}
                    buttonClass="hidden sml:block"
                    onClick={onContinueClick}
                    testDataAttribute="alert-mob-continue-caption"
                    title={continueCaption}
                    titleKey={continueCaptionKey}
                  /> 
              </>
              
            }
            {(cancelCaptionKey || cancelCaption) && !isProcessing && <>
                <Button
                  buttonType={ButtonType.SecondaryButton}
                  defaultContainerClass={"inline-block"} 
                  buttonClass="sml:hidden"                  
                  disabled={isProcessing} 
                  onClick={onCancelClick} 
                  testDataAttribute="alert-cancel-caption"
                  title={cancelCaption}
                  titleKey={cancelCaptionKey}
                />

                {/* Below button will only show for mobile devices & above will hide */}
                <Button
                  buttonType={ButtonType.MobileSecondary}
                  defaultContainerClass={"inline-block"} 
                  buttonClass="hidden sml:block"
                  disabled={isProcessing} 
                  onClick={onCancelClick} 
                  testDataAttribute="alert-mob-cancel-caption"
                  title={cancelCaption}
                  titleKey={cancelCaptionKey}
                />

                </>
            }
          </div>
        </div>
      </div>
    </>
  )
};

export default Alert