import { useEffect, useState } from 'react';
import useFeatureFlags from 'hooks/utils/featureFlags';
import usePermissions from 'hooks/session/permissions';
import { useUser, useDataHierarchy } from 'portal-common-ui';
import config from 'config/config';


const useDataAccess = () => {

    const { getAuthenticatedSessionUser } = useUser();
    const [sessionUser, setSessionUser] = useState(null);
    const hasVTAssigned = !!sessionUser?.userApplicationAssignments?.results.find(a => a?.assignedApplication?.applicationGuid === 'VT');
    const smallMerchantMidsThreshold = config.constants.smallMerchantMidsThreshold;
    const { isUnderSmallMerchantMidsThreshold } = useDataHierarchy();
    const { accountsDataAccessManagementEnabled, accountsSmallMerchantMidSelectionEnabled } = useFeatureFlags();
    const {hasPermission} = usePermissions();
    const enabledSelectMethodsObj: { [key: string]: boolean } = {
        'Hierarchy': hasPermission("CAN_ASSIGN_DATA_ACCESS_HIERARCHY"),
        'MID': true,
        'DataAccessGroup': accountsDataAccessManagementEnabled && hasPermission('MANAGE_DATA_ACCESS_GROUPS')
    }

    useEffect(() => {
        const fetchSessionUser = async () => {
          const loggedInUser = await getAuthenticatedSessionUser();
          setSessionUser(loggedInUser);
        };
    
        fetchSessionUser();
    }, []);

    const allowSmallMerchantMidSelection = accountsSmallMerchantMidSelectionEnabled && sessionUser?.roleObject?.roleType === "EXTERNAL" && !hasVTAssigned;
    const meetsSmallMerchantMidsThreshold = isUnderSmallMerchantMidsThreshold({ sessionUser, midsThreshold: smallMerchantMidsThreshold });

    const formatInitialDataAccess = (dataAccess: {[key: string]: any}[]) => {
        return dataAccess?.filter(m => m?.searchBy?.value !== 'DataAccessGroup')?.concat(dataAccess?.filter(m => m?.searchBy?.value === 'DataAccessGroup').filter((item, index, objects) => {
            if (index === 0 || item?.selectedGroup?.id !== objects[index - 1]?.selectedGroup?.id) {
                return item;
            } 
        }))?.map(r => {
               
          return {
                accessType:r.searchBy?.value,
                rowKey: r.rowKey,
                hierarchyId: !['MID', 'DataAccessGroup'].includes(r.searchBy?.value) ? r.selectedHierarchy.hierarchyId : null,
                midHierarchyIds: r.searchBy?.value === 'MID' 
                  ? r.selectedMids.map((m: any) => m.hierarchyId)
                  : r.searchBy?.value !== 'DataAccessGroup' ? r.selectedMids : null,
                dagId: r?.selectedGroup?.id
                };
            });
        }


    return {
        enabledSelectMethods: Object.keys(enabledSelectMethodsObj).filter(key => enabledSelectMethodsObj[key]),
        formatInitialDataAccess,
        allowSmallMerchantMidSelection,
        meetsSmallMerchantMidsThreshold,
        smallMerchantMidsThreshold
    }
}

export default useDataAccess;