import { BVLoader20, CellShape, Grid, LocalizedText, OmniSearch, useRoles, useTranslations, useUser, useLocale } from "portal-common-ui";
import { gridInterfaces } from "portal-common-ui/dist/interfaces";
import config from "config/config";
import { useState, useEffect, useRef } from "react";
import useStateRef from "react-usestateref";
import { useGlobal } from 'libs/reactn';
import Modali, { useModali } from 'modali';
import UserProfileOverview from "components/Profile/UserProfileOverview";
import { useClickAway } from 'react-use';
import useAlertQueue from 'hooks/alertQueue';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons"
import { faCircle } from '@fortawesome/free-solid-svg-icons';


interface Props {
    setUserToClone: any;
}

const SelectCloneUser: React.FC<Props> = ({ setUserToClone }) => {
    const userUrl = config.API.userUrl2;
    const relativeUrl = `/search`;
    const [filtersUpdatedToggle, setFiltersUpdatedToggle] = useState<boolean>(false);
    const { getRoleDisplayName } = useRoles();
    const [, setRowSelected, rowSelectedRef] = useStateRef([{}])
    const [, setOmniSearch, omniSearchRef] = useStateRef([]);
    const { translate } = useTranslations();
    const [viewUserModal, toggleViewUserModal] = useModali({
        overlayClose: false,
        large: true,
        centered: true,
      });
    const [basicInfoState, setBasicInfoState] = useStateRef({
        firstName: "",
        lastName: "",
        email: "",
        brands: [],
        role: {},
        userHasMoreBrands: false
      });
    const [dataAccessState, setDataAccessState] = useStateRef([]);
    const [cloneUserProfile, setCloneUserProfile] = useStateRef(null);
    const { getUserById, isLoading, getAuthenticatedSessionUser} = useUser();
    const [currentUser, setCurrentUser] = useStateRef({brands: []});
    const clickRef = useRef(null);
    const alertQueue = useAlertQueue();
    const [localeDictionary] = useGlobal('localeDictionary');

    useClickAway(clickRef, () => {
        if (viewUserModal.isShown) {
            toggleViewUserModal(false);
        }
    });

    useEffect(() => {
        const fetch = async () => {
          const userResult = await getAuthenticatedSessionUser();
          setCurrentUser(userResult);
        };
        fetch();
      }, []);

      const transformRow = (rows: any[]) => {
        const inRows = rows;
        const outRows = inRows.map(inRow => {
            let roleName = inRow.accountsApplicationRoleGuid
                .split('_')
                .map(r => r.charAt(0) + r.substr(1).toLowerCase())
                .join('');
            const roleKey = 'applications.mp-accounts.role.merchant'+roleName+'.name';
            const statusKey = 'userManagementPage.filterBar.filters.status.' + inRow.status.toLowerCase();
            
            let outRow = {
                email: inRow.email,
                userId: inRow.userId,
                brand: `${inRow.brands.join(', ')} ${inRow.hasInaccessibleBrands ? '*' : ''}`,
                firstName:inRow.firstName,
                lastName:inRow.lastName,
                roleGuid: inRow.accountsApplicationRoleGuid,
                role: translateToString(roleKey),
                lastLoginDate: inRow.lastLoginDate ? inRow.lastLoginDate : null,
                status: translateToString(statusKey),
                statusGuid: inRow.status.toUpperCase(),
                canEditStatus: true
            };
            return outRow;
        });
        return outRows;
    };

    const serverDataSource: gridInterfaces.IServerDatasource = {
        url: `${userUrl}${relativeUrl}`,
        reqType: 'post',
        sortMap: { 'default': 'LAST_NAME', 'lastName': 'LAST_NAME', 'firstName': 'FIRST_NAME', 'email': 'EMAIL', 'accountsApplicationRoleGuid': 'MP_ACCOUNTS_APP_ROLE_GUID', 'status': 'STATUS' },
        transformRow,
        reqBody: {
            omniSearchValues: omniSearchRef.current,
            includeInactive: true,
            statuses: ['ACTIVE', 'INACTIVE'],
        },
        filtersUpdatedToggle
    }

    const renderStatusColumn = ({ data }: {[key: string]: any}) => {
        const theme = data.statusGuid ? getTheme(data.statusGuid) : data.status ? getTheme(data.status):'';
        return (
          <div>
          <div className={`pill-status min-w-[60px]`} style={{ borderColor: theme, color: theme}} data-test={'grid-column-status'}>
            <FontAwesomeIcon icon={faCircle} className='leading-0 text-8px mr-1.5' style={{color: theme}} /> 
            <span className="font-medium">{data.status}</span>
          </div>
          </div>
        );
      };

    const loaderColumnDefs = [
        { width: "2%", cellShape: CellShape.Bar },
        { width: "8%", cellShape: CellShape.Bar },
        { width: "31%", cellShape: CellShape.Bar },
        { width: "21%", cellShape: CellShape.Bar },
        { width: "21%", cellShape: CellShape.Bar },
        { width: "8%", cellShape: CellShape.Pill },
        { width: "21%", cellShape: CellShape.Bar },

    ];

    const roleNameToDisplay = (params: any) => {
        let roleName = params.data.roleGuid
            .split('_')
            .map(r => r.charAt(0) + r.substr(1).toLowerCase())
            .join('');
        const roleKey = 'applications.mp-accounts.role.merchant' + roleName + '.name';

        return translateToString(roleKey);
    }

    const getTheme = (statusGuid: any) => {
        let theme;
        switch (statusGuid) {
            case 'ACTIVE':
                theme = '#468000';
                break;
            case 'LOCKED':
                theme = '#ce232a';
                break;
            case 'INACTIVE':
            case 'DEACTIVATED':
            default:
                theme = '#5A5E6D';
        }
        return theme;
    }

    const isSortable = localeDictionary?.locale === "en-US";

    const onUserSelected = async(row: any) => {
        setRowSelected(row);
        setUserToClone(row[0]);
    }

    const onSearch = (newOmniSearch: any) => {
        setOmniSearch(newOmniSearch);
        if ((newOmniSearch[0]?.length >= 2 || newOmniSearch[0]?.length === 0) && newOmniSearch?.length <= 3) {
            setFiltersUpdatedToggle(!filtersUpdatedToggle);
            setUserToClone({})
            setRowSelected([])
        }
    }

    const showError = () => {
      alertQueue.enqueueAlert({
        alertType: "modal",
        messageType: "error",
        title: "Error Retrieving User Profile",
        message:
            "We're sorry, but we were unable to retrieve this user's profile information. Please try again. If this issue persists, please contact our support team.",
      });
    };

    const onViewUserClick = async (data: any) => {
        setCloneUserProfile(null);
        toggleViewUserModal(true);
        const {firstName, lastName, email} = data;
        const userResult = await getUserById(data.userId);
        if (!userResult) {
            toggleViewUserModal(false);
            showError();
            return;
        }
        const brands = userResult.brands.filter((value: string) => currentUser?.brands.includes(value));
        let userHasMoreBrands = false;
        if (userResult.brands.length > currentUser.brands.length || userResult.brands.some((value: string) => !currentUser.brands.includes(value))) {
            userHasMoreBrands = true;
        }
        setBasicInfoState({
          firstName,
          lastName,
          email,
          brands,
          userHasMoreBrands,
          role: userResult.roleObject
        });
        setCloneUserProfile(userResult);
        setDataAccessState(userResult.dataAccess);
    }

    const getActionItems: gridInterfaces.IActionsColumn['getActionItems'] = () => {
        return [
            {
                iconClass: 'fa fa-eye text-lg text-theme-primary',
                captionKey: "shared.emptyString",
                captionClass: 'text-theme-info',
                handler: onViewUserClick,
                dataTestAttribute: 'clone-view-user',
            },
        ]
    }
    const { translateToString } = useLocale();

    return (
        <>
            <div className="bg-theme-on-primary flex flex-col p-4 border border-t-0 border-b-0 border-theme-light-border w-full overflow-x-hidden h-[380px] t-xl:h-full" data-test="clone-user-page">

                    <div className="font-medium text-base leading-6 text-theme-dark">
                        {<LocalizedText localeKey="cloneUser.sections.selectUserCloneTab.subTitle"/>} 
                    </div>

                    <div>
                        <OmniSearch
                            placeholder={translateToString("cloneUser.sections.selectUserCloneTab.searchPlaceholder")}
                            searchHandler={onSearch}
                        ></OmniSearch>
                    </div>

                    {omniSearchRef.current?.length > 0 && <div className="w-full font-medium text-sm leading-5 text-theme-dark pt-1.5">
                        <LocalizedText localeKey="cloneUser.sections.selectUserCloneTab.searchResults"/>:

                        <div className="pr-6 pt-4 mb-2">
                            <Grid
                                rowModelType="serverSide"
                                serverDatasource={serverDataSource}
                                rowIdFieldName="userId"
                                wrapperClass="ag-grid__container w-full grid-common-ag-grid bg-theme-on-primary border border-solid border-gray-300 rounded-sm"
                                agGridClassName="ag-grid w-full userassigned-ag-grid-height overflow-y-hidden overflow-x-auto"
                                sortDefault="lastName"
                                loaderColumnDefs={loaderColumnDefs}
                                onRowSelected={onUserSelected}
                                paginationRowsTitleKey="cloneUser.pagination.title"
                                selectedRows={rowSelectedRef.current}
                            >
                                <Grid.RadioButtonColumn
                                    minWidth={50}
                                    maxWidth={50}
                                    suppressMovable
                                />
                                <Grid.TextColumn
                                    field="lastName"
                                    headerNameKey="cloneUser.columns.lastName"
                                    accentuate
                                    sortable
                                    resizable
                                    suppressMovable
                                    minWidth={150}
                                />
                                <Grid.TextColumn
                                    field="firstName"
                                    headerNameKey="cloneUser.columns.firstName"
                                    accentuate
                                    sortable
                                    resizable
                                    suppressMovable
                                    minWidth={150}
                                />

                                <Grid.TextColumn
                                    field="email"
                                    headerNameKey="cloneUser.columns.email"
                                    sortable
                                    resizable
                                    suppressMovable
                                    minWidth={250}
                                />

                                <Grid.TextColumn
                                    field="accountsApplicationRoleGuid"
                                    headerNameKey="cloneUser.columns.role"
                                    valueGetter={roleNameToDisplay}
                                    sortable={isSortable}
                                    resizable
                                    suppressMovable
                                    minWidth={200}
                                />

                                <Grid.CustomColumn
                                    field="status"
                                    headerNameKey="cloneUser.columns.status"
                                    getTheme={getTheme}
                                    sortable={isSortable}
                                    suppressMovable
                                    resizable
                                    minWidth={120}
                                    customComponent={renderStatusColumn}
                                />

                                <Grid.ActionsColumn
                                    field='actions'
                                    headerNameKey="cloneUser.columns.view"
                                    minWidth={85}
                                    maxWidth={85}
                                    getActionItems={getActionItems}
                                    pinned='right'
                                    suppressMovable
                                />

                                <Grid.Pagination />
                            </Grid>
                        </div>
                    </div>}
            </div>
            <Modali.Modal {...viewUserModal}>
                <div ref={clickRef}>
                    <div
                    className="w-full inline-flex items-center py-[20px] pl-[14px] pr-[2px] bg-theme-on-primary border border-solid border-gray-100"
                    style={{ height: 64 }}
                    >
                    <div
                        className="w-full flex space-x-12 items-center justify-start px-[16px]"
                        style={{ height: 24 }}
                    >
                        <div className="flex w-full justify-start text-base font-medium leading-normal text-theme-dark gap-12 items-center">
                            {basicInfoState.firstName && <span><LocalizedText localeKey="cloneUser.sections.selectUserCloneTab.cloneViewIcon.header"/> - {basicInfoState.firstName}{" "}{basicInfoState.lastName}</span>}
                        </div>
                    </div>
                    <button
                        onClick={() => toggleViewUserModal(false)}
                        className="close bg-theme-medium text-theme-on-primary absolute w-[20px] h-[20px] rounded-full text-xs"
                        style={{ top: "10px", right: "10px" }}
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    </div>
                    {isLoading && <div style={{minHeight: '380px'}}><BVLoader20 /></div>}
                    {cloneUserProfile && !isLoading && (
                        <div className="p-4">
                            <UserProfileOverview
                                basicInfoState={basicInfoState}
                                dataAccessState={dataAccessState}
                                userProfile={cloneUserProfile}
                                selectedGroups={[]}
                                type='clone'
                            />
                        </div>
                    )}
                </div>
            </Modali.Modal>
        </>
    )

}

export default SelectCloneUser;