import { ReactComponent as EyeImage } from "assets/icons/eye_2.svg";
import {
  useUser,
  NotificationCard,
  useTranslations,
  BVLoader20,
  LocalizedText,
  useLocale
} from "portal-common-ui";
import { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import config from "config/config";
import BasicInformation from "components/BasicInformation/BasicInformation";
import useStateRef from "react-usestateref";
import Modali, { useModali } from "modali";
import UserProfileOverview from "components/Profile/UserProfileOverview";
import { useClickAway } from 'react-use';
import useValidateEmail from 'hooks/user/email';
import useAlertQueue from 'hooks/alertQueue';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { isDesktop } from 'react-device-detect';

interface BasicInformationCloneUserProps {
  formData: any;
  userToClone: any;
  setUserToClone: any;
  showRoleSelector: any;
  emailError: any;
  setEmailError: any;
  setUserFetching: any;
  methods: any;
  currentStepName: 'creationComplete' | 'basicInfo' | 'reviewProfile' | 'selectUserToClone';
}

type UserParams = {
  userId: string;
}

const BasicInformationCloneUser: React.FC<BasicInformationCloneUserProps> = ({
    formData,
    userToClone,
    setUserToClone,
    showRoleSelector,
    emailError = false,
    setEmailError = () => null,
    setUserFetching = () => null,
    methods,
    currentStepName,
}) => {
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState<Array<{}>>([]);
  const { translate } = useTranslations();
  const { getAuthenticatedSessionUser, getUserById, isLoading } = useUser();
  const [viewUserModal, toggleViewUserModal] = useModali({
    overlayClose: false,
    large: true,
    centered: true,
  });
  const defaultBasicInfo = {
    firstName: "",
    lastName: "",
    email: "",
    brands: [],
    role: {},
    userHasMoreBrands: false
  };
  const [basicInfoState, setBasicInfoState] = useStateRef(defaultBasicInfo);
  const [dataAccessState, setDataAccessState] = useStateRef({});
  const [currentUser, setCurrentUser] = useStateRef({brands: []});
  const clickRef = useRef(null);
  const alertQueue = useAlertQueue();
  const history = useHistory();
  let brandDetails = JSON.parse(`${sessionStorage.getItem('brandDetails')}`);
  const { translateToString } = useLocale();

  useClickAway(clickRef, () => {
    if (viewUserModal.isShown) {
      toggleViewUserModal(false);
    }
  });

  const [cloneUserProfile, setCloneUserProfile, cloneUserProfileRef] = useStateRef({});
  const { validate } = useValidateEmail();
  const params = useParams<UserParams>();

  const userId = params.userId || userToClone.userId;

  useEffect(() => {
    setEmailError(false);
    if (userId) {
      fetchSelectedUserDetails().then(() => {
        const { email } = methods.getValues();
        validateEmailAddressClone(email);
      });
    }
  }, [userId]);

  const onBrandSelected = (brand) => {
    setSelectedBrands(brand);
  };

  const validateEmailAddressClone = async (input: string) => {
    const emailInput = input?.trim();
    if (!emailInput) {
      return;
    }
    setUserFetching(true);
    const isValidEmail = await validate(emailInput);
    setUserFetching(false);
    const cloneUserInternal = cloneUserProfileRef.current?.roleObject?.roleType === "INTERNAL";
    const currentUserInternal = currentUser?.roleObject?.roleType === "INTERNAL";
    const internalCloningInternalUser = currentUserInternal && cloneUserInternal && !isValidEmail;
    const externalCloningExternalUser = !currentUserInternal && !cloneUserInternal && isValidEmail;
    setEmailError(internalCloningInternalUser || externalCloningExternalUser);
  }

  const fetchSelectedUserDetails = async () => {
    setBasicInfoState(defaultBasicInfo);
    setCloneUserProfile({});
    setUserFetching(true);
    const { user: selectedUser, ok, error } = await getUserById(userId, true);
    setUserFetching(false)
    let titleKey = "cloneUser.warnings.retrieveProfile";
    let messageKey = "cloneUser.warnings.unableRetrieve";
    let continueAction = () => {};
    if (!ok) {
      if (
        error.resourceKey === "user.hierarchyAssignment.serviceError" ||
        (error.resourceKey === "request.accessDenied" &&
          error.errorCode ===
            "The calling user's location access is not sufficient to retrieve this user")
      ) {
        // Note: due to accessDenied is generic resourceKey, added errorCode string check
        titleKey = "cloneUser.warnings.cannotClone";
        messageKey = "cloneUser.warnings.restrictClone";
        if (currentStepName === 'basicInfo') {
          continueAction = () => {
            history.push(`/user-management`);
          };
        }
      }
      alertQueue.enqueueAlert({
        alertType: "modal",
        messageType: "error",
        titleKey,
        messageKey,
        continueAction,
      });
      setUserToClone({});
      return;
    } else {
      const isTransient = selectedUser?.dataAccess?.every((d: any) =>
        d?.searchBy?.label === "DAG" && d?.userHierarchyAssignmentId === "TRANSIENT"
      );
      if (isTransient) {
        titleKey = "cloneUser.warnings.cannotClone";
        messageKey = "cloneUser.warnings.restrictClone";
        if (currentStepName === 'basicInfo') {
          continueAction = () => {
            history.push(`/user-management`);
          };
        }
        alertQueue.enqueueAlert({
          alertType: "modal",
          messageType: "error",
          titleKey,
          messageKey,
          continueAction,
        });
        setUserToClone({});
        return;
      }
    }
    const sessionUser = await getAuthenticatedSessionUser();
    const filteredBrands = sessionUser.brands.filter(value => selectedUser.brands.includes(value));
    const brandsOptions = filteredBrands.map((brand: string) =>
      brand.toLowerCase() === brandDetails.brandGuid.toLowerCase() ||
      filteredBrands.length === 1
      ? { value: brand, label: brand, isFixed: true }
      : { value: brand, label: brand }
    );
    const loggedInUserApps = sessionUser.userApplicationAssignments.results.map(
      (app: { applicationId: any }) => app.applicationId
    );
    selectedUser.userApplicationAssignments.results =
      selectedUser.userApplicationAssignments.results.filter(
        (app: { applicationId: any }) =>
          loggedInUserApps.includes(app.applicationId)
      );
    setSelectedBrands([...brandsOptions]);
    if (brandsOptions.length > 1) {
      brandsOptions.unshift({ label: translateToString('createUser.sections.basicInfo.form.selectBrands.allBrands'), value: "all" });
    }
    setCurrentUser(sessionUser);
    setBrands(brandsOptions);
    const brands = selectedUser.brands.filter((value: string) => sessionUser.brands.includes(value));
    let userHasMoreBrands = false;
    if (selectedUser.brands.length > sessionUser.brands.length || selectedUser.brands.some((value: string) => !sessionUser.brands.includes(value))) {
        userHasMoreBrands = true;
    }
    setBasicInfoState({
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      email: selectedUser.email,
      brands,
      userHasMoreBrands,
      role: selectedUser.roleObject
    });
    setDataAccessState(selectedUser.dataAccess);
    setCloneUserProfile(selectedUser);
    setUserToClone(selectedUser);
  }

  return (
    <>
      <form className="w-full h-full">
        <div
          className="bg-theme-on-primary border border-t-0 border-b-0 border-theme-light-border w-full p-4 overflow-x-hidden h-[380px] t-xl:h-full"
        >
            <div
              className="flex flex-row space-x-12 items-start justify-start pb-2 w-[990px] pr-4 xl:w-[990px] pt-4"
              style={{ minHeight: 100 }}
            >
              <div className="flex items-center h-14 min-w-[380px] text-sm border border-solid border-theme-light-border bg-theme-light-background px-[20px]">
                <div className="mr-[10px] text-theme-dark not-italic text-base leading-6 font-medium">
                  {<LocalizedText localeKey="cloneUser.sections.newUserInfoTab.viewTitle"/>}
                </div>
                <div className="flex-grow font-medium leading-6 text-lg text-theme-info">
                  {userToClone?.firstName} {userToClone?.lastName}
                </div>
                {userToClone?.firstName && (
                  <button
                    type="button"
                    className="bg-theme-light-background border-0 pl-2"
                    onClick={() => toggleViewUserModal(true)}
                    aria-label="Toggle View User Modal"
                  >
                    <EyeImage />
                  </button>
                )}
              </div>
            </div>
            {cloneUserProfile?.hasMidsExcludedFromLoggedInUser && 
             <NotificationCard
              type="warning"
              title={<LocalizedText localeKey="roleChange.notifications.titles.dataAccessWarning"/>}
              messageLine1={<><LocalizedText localeKey="roleChange.notifications.messages.cloneAccess"/> <LocalizedText localeKey="roleChange.notifications.messages.accessGiven"/></>}
             />
            }
            <hr
              className="border-theme-light-border w-full "
              style={{ height: 1.18 }}
            />
            <div data-test="clone-user-basic-info">
              <div className="font-medium text-base leading-6 text-theme-dark pb-4 pt-1 ">
                {<LocalizedText localeKey="cloneUser.sections.newUserInfoTab.subTitle"/>}:
              </div>
              <BasicInformation
                onBrandSelected={onBrandSelected}
                multiBrandAvailable={brands.length > 1}
                formData={formData}
                selectedBrands={selectedBrands}
                brands={brands}
                showRoleSelector={showRoleSelector}
                isInternal={
                  cloneUserProfile?.roleObject?.roleType === "INTERNAL"
                }
                emailError={emailError}
                validateEmailAddressClone={validateEmailAddressClone}
                setEmailError={setEmailError}
              />
          </div>
        </div>
      </form>
      <Modali.Modal {...viewUserModal}>
        <div ref={clickRef}>
          <div
            className="w-full inline-flex items-center py-[20px] pl-[14px] pr-[2px] bg-theme-on-primary border border-solid border-gray-100"
            style={{ height: 64 }}
          >
            <div
              className="w-full flex space-x-12 items-center justify-start px-[16px]"
              style={{ height: 24 }}
            >
              <div className="flex w-full justify-start text-base font-medium leading-normal text-theme-dark gap-12 items-center">
                {basicInfoState.firstName && (
                  <span>
                    <LocalizedText localeKey="cloneUser.sections.selectUserCloneTab.cloneViewIcon.header"/> - {basicInfoState.firstName}{" "}
                    {basicInfoState.lastName}
                  </span>
                )}
              </div>
            </div>
            <button
              className="close bg-theme-medium text-theme-on-primary absolute w-[20px] h-[20px] rounded-full text-xs"
              onClick={() => toggleViewUserModal(false)}
              style={{ top: "10px", right: "10px" }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          {isLoading && (
            <div style={{ minHeight: "380px" }}>
              <BVLoader20 />
            </div>
          )}
          {cloneUserProfile.userId && !isLoading && (
            <div className="p-4">
              <UserProfileOverview
                basicInfoState={basicInfoState}
                dataAccessState={dataAccessState}
                userProfile={cloneUserProfile}
                selectedGroups={[]}
                type="clone"
              />
            </div>
          )}
        </div>
      </Modali.Modal>
    </>
  );
};

export default BasicInformationCloneUser;
