import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useStateRef from "react-usestateref";
import { useGlobal } from 'libs/reactn';
import { faUserGroup, faUser } from '@fortawesome/pro-light-svg-icons';
import { ColumnFunctionCallbackParams } from 'ag-grid-community';
import { gridInterfaces, filter } from "portal-common-ui/dist/interfaces";
import {
  CustomFilter,
  FilterBar,
  Grid,
  GridHeader,
  SearchByMid,
  useUser,
  useRoles,
  useLocale
} from "portal-common-ui";
import useAlertQueue from "hooks/alertQueue";
import { localeKeys } from "typings/translate";
import 'portal-common-ui/dist/portal-common-ui.css';
import useUserList from 'hooks/use-user-list';
import config from 'config/config';
import usePermissions from 'hooks/session/permissions';
import useSession from "hooks/session/session";
import DeleteUserWarning from "./DeleteUserWarning";
import useFeatureFlags from "hooks/utils/featureFlags";
import useDataAccess from "hooks/utils/dataAccess";
import CustomCreateUserButton from "./CustomCreateUserButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons"; 
import { isMobile, isMobileOnly, isDesktop} from 'react-device-detect';
import { render } from "react-dom";
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import EllipsisText from "react-ellipsis-text";

const UserManagement: React.FC = () => {
  const history = useHistory();
  const user = useUser();
  const { getRoles } = useRoles();
  const session = useSession();
  const { allowSmallMerchantMidSelection, meetsSmallMerchantMidsThreshold } = useDataAccess();
  const { accountsUserManagementLockedFilterEnabled, accountsCloneUserEnabled, accountsDbaNameUserFilter, accountsPrivacyTermsLinksEnabled, accountsInactiveStatusFilterEnabled, accountsRequestAccessStatusEnabled } = useFeatureFlags();
  const [filtersUpdatedToggle, setFiltersUpdatedToggle] = useState<boolean>(false)
  const [, setFilters, filtersRef] = useStateRef<{ [key: string]: any }>({});
  const [, setOmniSearch, omniSearchRef] = useStateRef<string[]>([]);
  const [filtersReceived, setFiltersReceived] = useState<boolean>(false);
  const userUrl = config.API.userUrl2;
  const relativeUrl = '/search';
  const { transformRow, sortMap, loaderColumnDefs } = useUserList();
  const { translateToString } = useLocale();
  const { enqueueAlert } = useAlertQueue();
  const { hasPermission } = usePermissions();
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [userToDelete, setUserToDelete] = useState('');
  const [roleOptions, setRoleOptions] = useState<any[]>([]);
  const [brandOptions, setBrandOptions] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
const [localeDictionary] = useGlobal('localeDictionary');

  useEffect(() => {
    const fetch = async () => {
      const userEmailAddress = session?.user?.email;
      const responseRoles: any[] = await getRoles(userEmailAddress);
      const userResult = await user.getAuthenticatedSessionUser();
      const roles = responseRoles.map(role => {
        role.id = role.roleGuid;
        return role;
      });
      setRoleOptions(roles);
      if (userResult?.brands) {
        setBrandOptions(
          userResult.brands.map((brand: string) => {
            return { id: brand, nameKey: `userManagementPage.filterBar.filters.brand.brands.${brand.toLowerCase()}` };
          })
        );
      }
    }

    fetch();
  }, []);

  const filterInputObj: any = {};

  Object.keys(filtersRef.current).forEach(
    (key) =>
      (filterInputObj[key] = Array.isArray(filtersRef.current[key])
        ? filtersRef.current[key].map((item: any) =>
            item?.value ? item?.value : item
          )
        : filtersRef.current[key])
  );

  const serverDatasource: gridInterfaces.IServerDatasource = {
    url: `${userUrl}${relativeUrl}`,
    reqType: 'post',
    sortMap,
    transformRow,
    reqBody: {
      ...filterInputObj,
      omniSearchValues: omniSearchRef.current,
      includeInactive: true,
    },
    filtersUpdatedToggle,
  }

  interface IShowWarning {
    continueCaption?: string,
    continueCaptionKey?: localeKeys,
    continueAction: () => void,
    message?: string,
    messageKey?: localeKeys
  }

  const showWarning = ({ continueCaption, continueCaptionKey, continueAction, message, messageKey }: IShowWarning) => {
    enqueueAlert({
      alertType: 'modal', 
      titleKey: "shared.alerts.titles.warning", 
      messageType: 'warning', 
      continueCaption,
      continueCaptionKey,
      cancelCaptionKey: "shared.alerts.cancelAction",
      continueAction: continueAction,
      cancelAction: () => {},
      message,
      messageKey
    });
  }

  const onActivateUser = (data: ColumnFunctionCallbackParams['data'], api: ColumnFunctionCallbackParams['api']) => {
    const activateUser = async () => {
      try {
        const response = await user.activateUser(data.userId);
        if(response.status !== 200) {
          throw new Error()
        }
        enqueueAlert({ alertType: 'toast', messageType: 'success', messageKey: "userManagementPage.toasts.success.activateUser.message", transitionDuration: 6000});
        setTimeout(() => api.refreshServerSideStore({purge: true}), 6000);  
      } catch(e) {
        enqueueAlert({ alertType: 'toast', messageType: 'error', messageKey: "userManagementPage.toasts.error.activateUser.message", transitionDuration: 6000});
      }
    }
    if(data?.statusGuid === 'LOCKED') {
      showWarning({
        continueCaptionKey: "userManagementPage.alerts.warning.activateLockedUser.continueCaption",
        continueAction: activateUser,
        messageKey: "userManagementPage.alerts.warning.activateLockedUser.message" 
      })
    } else if (data?.statusGuid === 'DEACTIVATED') {
      showWarning({
        continueCaptionKey: "userManagementPage.alerts.warning.activateLockedUser.continueCaption",
        continueAction: activateUser,
        messageKey: "userManagementPage.alerts.warning.activateDeActivatedUser.message" 
      })
    } else {
      activateUser()
    }
  }

  const onDeactivateUser = (data: ColumnFunctionCallbackParams['data'], api: ColumnFunctionCallbackParams['api']) => {
    const deactivateUser = async () => {
      await user.deactivateUser(data?.userId);
      enqueueAlert({ alertType: 'toast', messageType: 'info', messageKey: 'userManagementPage.toasts.info.deActivateUser.message', transitionDuration: 6000});
      setTimeout(() => api.refreshServerSideStore({purge: true}), 6000);    
    }

    showWarning({
      continueCaptionKey: 'userManagementPage.alerts.warning.deActivateUser.continueCaption',
      continueAction: deactivateUser,
      messageKey: 'userManagementPage.alerts.warning.deActivateUser.message'
    })

  };

  const onDeclineAccess = (data: ColumnFunctionCallbackParams['data'], api: ColumnFunctionCallbackParams['api']) => {
    const declineAccess = async () => {
      await user.declineAccess(data?.userId);
      enqueueAlert({ alertType: 'toast', messageType: 'info', messageKey: 'userManagementPage.toasts.info.userDeniedAccess.message', transitionDuration: 6000});
      setTimeout(() => api.refreshServerSideStore({purge: true}), 6000);    
    }

    showWarning({
      continueCaptionKey: 'userManagementPage.actions.declineAccess',
      continueAction: declineAccess,
      messageKey: 'userManagementPage.alerts.warning.denyRequestAccess.message'
    })

  };

  const onLockUser = (data: ColumnFunctionCallbackParams['data'], api: ColumnFunctionCallbackParams['api']) => {
    const lockUser = async () => {
      await user.lockUser(data.userId);
      enqueueAlert({ alertType: 'toast', messageType: 'info', messageKey: "userManagementPage.toasts.info.lockUser.message", transitionDuration: 6000});
      setTimeout(() => api.refreshServerSideStore({purge: true}), 6000);
    }

    showWarning({
      continueCaptionKey: "userManagementPage.alerts.warning.lockUser.continueCaption",
      continueAction: lockUser,
      messageKey: "userManagementPage.alerts.warning.lockUser.message"
    });

  }

  const onDeleteUser = (data: ColumnFunctionCallbackParams['data']) => {
    setUserToDelete(data?.userId)
  }

  const getActionItems: gridInterfaces.IActionsColumn['getActionItems'] = (data: ColumnFunctionCallbackParams['data']) => {

      const canEditUser = hasPermission('UPDATE_OTHER_USERS');  // todo: check to view user detail permission since button is view/edit
      const canActivateDeactivate = hasPermission('ACTIVATE_DEACTIVATE_USER_ACCOUNT_STATUS');
      const canDelete = hasPermission('DELETE_USERS');
      const hasLockedPermission = hasPermission('MANAGE_LOCKED_STATUS');
      const canCloneUser = accountsCloneUserEnabled && hasPermission('CREATE_USERS');
      const isActive = data.statusGuid === "ACTIVE";
      const isInactive = data.statusGuid === "INACTIVE";
      const isApprovalPending = data.statusGuid === "APPROVAL_PENDING";

    return [
        {
            iconClass: `fa ${canEditUser ? 'fa-user-edit' : 'fa-user'} text-theme-info text-base font-medium`,
            captionKey: isApprovalPending && accountsRequestAccessStatusEnabled ? 'userManagementPage.actions.assignAccess' : canEditUser ? 'userManagementPage.actions.viewEditUser' : 'userManagementPage.actions.viewUser',
            captionClass: 'ml-1 font-medium font-roboto text-left p-1 w-full text-theme-info',
            handler: () => history.push(`/user/view/${data.userId}`),
        },
        {
            icon: faUserGroup,
            iconClass: 'text-theme-info text-base font-medium',
            captionKey: 'userManagementPage.actions.cloneUser',
            captionClass: 'ml-1 font-medium font-roboto text-left p-1 w-full text-theme-info',
            handler: () => history.push(`/clone/user/${data.userId}`, { from: data }),
            hidden: !(canCloneUser && (isActive || isInactive)),
        },
        {
            iconClass: 'fa fa-user-check text-theme-success text-base font-medium',
            captionKey: 'userManagementPage.actions.activate',
            captionClass: 'ml-1 font-medium font-roboto text-left p-1 w-full text-theme-success',
            handler: onActivateUser,
            hidden: !(canActivateDeactivate && ["INACTIVE", "LOCKED", "DEACTIVATED"].includes(data.statusGuid)),
        },
        {
            iconClass: 'fa fa-user-slash text-theme-danger text-base font-medium',
            captionKey: 'userManagementPage.actions.deactivate',
            captionClass: 'ml-1 font-medium font-roboto text-left p-1 w-full text-theme-danger',
            handler: onDeactivateUser,
            hidden: !(canActivateDeactivate && (isActive || isInactive)),
        },
        {
            iconClass: 'fa fa-user-slash text-theme-danger text-base font-medium',
            captionKey: 'userManagementPage.actions.declineAccess',
            captionClass: 'ml-1 font-medium font-roboto text-left p-1 w-full text-theme-danger',
            handler: onDeclineAccess,
            hidden: !(canCloneUser && isApprovalPending && accountsRequestAccessStatusEnabled),
        },
        {
            iconClass: 'fa fa-lock text-theme-danger text-base font-medium',
            captionKey: 'userManagementPage.actions.lock',
            captionClass: 'ml-1 font-medium font-roboto text-left p-1 w-full text-theme-danger',
            handler: onLockUser,
            hidden: !(hasLockedPermission && (isActive || isInactive || isApprovalPending)),
        },
        {
            iconClass: 'fa fa-trash-alt text-theme-danger text-base font-medium',
            captionKey: 'userManagementPage.actions.delete',
            captionClass: 'font-medium font-roboto text-left w-full text-theme-danger',
            handler: onDeleteUser,
            hidden: !canDelete,
            divider: true,
        },
    ]
  }

  const onCreateUser = () => history.push('/user/new');
  const onCloneUser = () => history.push('/clone/new');
  
  const onFiltersChanged = (newFilters: filter.IFilterData[], newOmniSearch: string[], selectedFilters: string[]) => {
    if (!filtersReceived) setFiltersReceived(true);
    
    setFilters(newFilters);
    setOmniSearch(newOmniSearch.filter(str => !!str));
    setFiltersUpdatedToggle(!filtersUpdatedToggle);
    setSelectedFilters(selectedFilters);
  }

  const getTheme = (statusGuid: any) => {
    let theme;
    switch (statusGuid) {
      case 'ACTIVE':
        theme = '#468000';
        break;
      case 'INACTIVE':
        theme = '#5A5E6D';
        break;
      case 'DEACTIVATED':
        theme = '#5A5E6D';
        break;
      case 'APPROVAL_PENDING':
        theme = '#ce232a';
        break;
      case 'LOCKED':
        theme = '#ce232a';
        break;
      default:
        theme = '#5A5E6D';
    }
    return theme;
  }

  const statusOptions: { nameKey: localeKeys, id: string }[] = [
    {nameKey: "userManagementPage.filterBar.filters.status.active", id: "ACTIVE"}, 
    {nameKey: "userManagementPage.filterBar.filters.status.deactivated", id: 'DEACTIVATED'},
  ];

  if (accountsRequestAccessStatusEnabled) {
    statusOptions.push({nameKey: "userManagementPage.filterBar.filters.status.approval_pending", id: 'APPROVAL_PENDING'});
  }

  if (accountsInactiveStatusFilterEnabled) {
    statusOptions.push({nameKey: "userManagementPage.filterBar.filters.status.inactive", id: 'INACTIVE'});
  }

  const filterOptions: { nameKey: localeKeys, [key: string]: any }[] = [
    {
      id: 1,
      nameKey: "userManagementPage.columns.status",
      type: "LIST",
      field: "statuses",
      placeholder: "Status",
      options:
      hasPermission("MANAGE_LOCKED_STATUS") && accountsUserManagementLockedFilterEnabled
        ? [...statusOptions, { id: "LOCKED", nameKey: "userManagementPage.filterBar.filters.status.locked" }]
        : statusOptions,
    },
    {
      id: 2,
      nameKey: "userManagementPage.filterBar.filters.role.role",
      type: "LIST",
      field: "accountsRoles",
      placeholder: "Role",
      options: roleOptions,
    },
    {
      id: 3,
      nameKey: "userManagementPage.filterBar.filters.dba.dba",
      type: "CUSTOM",
      component: function (
        onRemove: any,
        onFilter: (val: any, field: string) => void,
        initialValue: filter.FilterValueType
      ) {
        return (
          <CustomFilter
            key={this.name}
            filter={this}
            onRemove={onRemove}
            onFilter={(val: any) => {
              onFilter(val, this.field);
            }}
            initialValue={initialValue}
            dropdownRenderer={({
              handleFilters,
            }: {
              handleFilters: (val: any) => void;
            }) => (
              <SearchByMid
                onMidSelection={(val: any) => handleFilters(val)}
                showSelectButton={false}
                initialValue={initialValue}
                type="DBA"
                maxSelection={5}
                placeholderText={translateToString('userManagementPage.filterBar.filters.dba.placeholder')}
              />
            )}
            classes="midFilter"
          />
        );
      },
      field: "merchantNumbers",
      disabled: selectedFilters?.includes('userManagementPage.filterBar.filters.mid.mid'),
      hide: !accountsDbaNameUserFilter,
    },
    {
      id: 4,
      nameKey: "userManagementPage.filterBar.filters.mid.mid",
      type: "CUSTOM",
      component: function (
        onRemove: any,
        onFilter: (val: any, field: string) => void,
        initialValue: filter.FilterValueType
      ) {
        return (
          <CustomFilter
            key={this.name}
            filter={this}
            onRemove={onRemove}
            onFilter={(val: any) => {
              onFilter(val, this.field)
            }}
            formatValue={(val: any) => val.map((item: any) => item?.value.trim() || item)}
            initialValue={ initialValue }
            dropdownRenderer={({ handleFilters }: { handleFilters: (val: any) => void }) => (
              <SearchByMid
                onMidSelection={(val: any) => handleFilters(val)}
                showSelectButton={false}
                initialValue={initialValue?.map((mid: any) => { return { value: mid } })}
                type='MID'
              />
            )}
            classes="midFilter"
          />
        );
      },
      field: "merchantNumbers",
      disabled: selectedFilters?.includes('userManagementPage.filterBar.filters.dba.dba'),
    },
    {
      id: 5,
      nameKey: "userManagementPage.filterBar.filters.brand.brand",
      type: "LIST",
      field: "brands",
      placeholder: "Brand",
      options: brandOptions,
      hide: brandOptions.length <= 1,
    },
    {
      id: 6,
      nameKey: "userManagementPage.columns.lastLogin",
      type: "DATE",
      field: "lastLogin",
      placeholder: "lastLogin",
    }
  ];

  const onGridLoadingChange = (gridState: { [key: string]: any }) => setTotalRowCount(gridState.totalRowCount);

  const renderCustomNameColumn = ({ data }: {[key: string]: any }) => {
    return (
      <div data-test={'grid-column-name'}>
        <div className="font-medium text-sm" data-test={'grid-column-firstName'}>
        <EllipsisText text={`${data.firstName} ${data.lastName}`} length={isDesktop ? 50 : 40}/>
        </div>
        <div data-test={'grid-column-email'}>
          <EllipsisText text={data.email} length={isDesktop ? 50 : 40}/>
        </div>
    </div>
    );
  };

  const renderStatusColumn = ({ data }: { [key: string]: any} ) => {
    const theme = data.statusGuid ? getTheme(data.statusGuid) : data.status ? getTheme(data.status):'';
    return (
      <div>
      <div className={`pill-status min-w-[60px]`} style={{ borderColor: theme, color: theme}} data-test={'grid-column-status'}>
        <FontAwesomeIcon icon={faCircle} className='leading-0 text-8px mr-1.5' style={{color: theme}} /> 
        <span className="font-medium">{data.status}</span>
      </div>
      </div>
    );
  };


  

  const isSortable = localeDictionary?.locale === "en-US";

  return (
    <div data-test="user-management-page">
      { userToDelete && <DeleteUserWarning userId={userToDelete} onCancel={() => setUserToDelete('') } />}
      <div className="flex justify-between items-center">
        <GridHeader 
          headingKey={'homepage.sidebar.userManagement'}
          rowCount={totalRowCount}
          rowCountCaptionKey={'userManagementPage.header.totalUsers'}
        />
        { (!isMobileOnly || (isMobileOnly && allowSmallMerchantMidSelection && meetsSmallMerchantMidsThreshold)) &&
        <div className="mb-3.5">
          {accountsCloneUserEnabled ? 
            <CustomCreateUserButton
                actionButtonPermission={hasPermission('CREATE_USERS')}
                onCreateUser={onCreateUser}
                onCloneUser={onCloneUser}
            /> : 
            <>
              {hasPermission('CREATE_USERS') && (
                <button
                  onClick={onCreateUser}
                  className="btn btn-primary btn-alt p-1 px-4 flex items-center m-0 border border-solid border-gray-300 mb-3.5"
                >
                  <FontAwesomeIcon icon={faPlus} className="text-[1.25rem]"/>
                  <span className="ml-2 pt-px">Create New User</span>
                </button>
              )}
            </>
          }
        </div>}
      </div>
      <FilterBar
        filterOptions={filterOptions}
        onClearHandler={() => setFilters([])}
        onSearchHandler={onFiltersChanged}
        omniSearchEnable={true}
        omniSearchProps={{ placeholder: translateToString(isDesktop ? 'userManagementPage.filterBar.omnisearch.searchBy' : 'userManagementPage.filterBar.omnisearch.searchByNameOrEmail') }}
        className="ag-grid__filter bg-theme-on-primary border-b-0 border border-solid border-gray-300"
        retainFilters
      />
      {filtersReceived &&
        <Grid
            rowModelType="serverSide"
            serverDatasource={serverDatasource}
            rowIdFieldName="userId"
            wrapperClass={`ag-grid__container w-full ${accountsPrivacyTermsLinksEnabled ? 'h-grid-height-for-footer' : ''} grid-common-ag-grid bg-theme-on-primary border border-solid border-gray-300 rounded-sm`}
            agGridClassName={`ag-grid ${accountsPrivacyTermsLinksEnabled ? '' : 'ag-grid-height'} w-full overflow-auto`}
            sortDefault="lastName"
            loaderColumnDefs={loaderColumnDefs}
            onGridLoadingChange={onGridLoadingChange}
            retainSortings
            paginationClass="ag-grid__footer flex justify-between items-center p-4 bg-white border border-solid border-white ring-1 ring-gray-300"
        >
            {/* US380613 - Removed firstName, lastName and email(consolidated to one column(Name)) */}
            <Grid.CustomColumn
              field='firstName'
              headerNameKey="dataAccessGroup.columns.name"
              sortable
              resizable
              suppressMovable
              minWidth={280}
              maxWidth={400}
              customComponent={renderCustomNameColumn}
              hide={false}
            />
            <Grid.TextColumn
                field="role"
                headerNameKey="userManagementPage.columns.role"
                sortable={isSortable}
                resizable
                suppressMovable
                minWidth={170}
                maxWidth={220}
                hide={isMobileOnly}
            />
            <Grid.DateColumn
                field="lastLoginDate"
                headerNameKey="userManagementPage.columns.lastLogin"
                sortable
                resizable
                suppressMovable
                minWidth={165}
                maxWidth={205}
                hide={isMobileOnly}
            />
            <Grid.TextColumn
                field="brand"
                headerNameKey="userManagementPage.columns.brand"
                resizable
                suppressMovable
                minWidth={130}
                maxWidth={200}
                hide={isMobileOnly}
            />
            <Grid.CustomColumn
                field="status"
                headerNameKey="userManagementPage.columns.status"
                getTheme={getTheme}
                sortable={isSortable}
                resizable
                suppressMovable
                minWidth={130}
                hide={isMobileOnly}
                customComponent={renderStatusColumn}
            />

          <Grid.ActionsColumn
            field='actions'
            headerNameKey='userManagementPage.columns.actions'
            minWidth={85}
            maxWidth={140}
            editable
            singleClickEdit
            pinned='right'
            suppressMovable
            getActionItems={getActionItems}
          />

            <Grid.Pagination />
        </Grid>
      }
    </div>
  );
};

export default UserManagement;